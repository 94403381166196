import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles, ITheme } from 'core/style/interfaces';
import React, { FC } from 'react';
import TextBlock from '../pages/TextBlock';
import { card } from 'core/style';
import c from 'classnames';

type IAdvertisingCourse = WithStyles<typeof styles> & {
    className?: string;
    slug: string;
    inCarousel?: boolean;
};

const AdvertisingCourse: FC<IAdvertisingCourse> = ({ classes, slug, inCarousel = false, className }) => {
    return (
        <div
            className={c(
                className,
                classes.container,
                classes.baseLink,
                inCarousel ? classes.linkFlex : classes.linkBlock,
            )}
        >
            <TextBlock slug={slug} />
        </div>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        container: {
            flex: '1 1 auto',
            width: '100%',
            maxWidth: 350,
            overflow: 'hidden',
            ...card(theme),
            borderStyle: 'none',
            textAlign: 'center',
            '& > div': {
                height: '100%',
            },
            '& img': {
                width: '100%',
                maxWidth: 200,
                maxHeight: 180,
                margin: '0 auto',
            },
        },
        baseLink: {
            '& a': {
                boxSizing: 'border-box',
                padding: 15,
                height: '100%',
                textDecoration: 'none',
                color: 'black',
                [theme.breakpoints.down('xs')]: {
                    padding: 5,
                },
            },
        },
        linkBlock: {
            '& a': {
                display: 'block',
            },
        },
        linkFlex: {
            '& a': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
            },
        },
    });

export default injectSheet(styles, 'AdvertisingCourse')(AdvertisingCourse);
