import { gql } from 'apollo-boost';
import CenterLoader from 'core/components/common/CenterLoader';
import { useDeviceInfo } from 'core/components/DeviceInfoContext';
import { useLinks } from 'core/components/linksContext';
import { button, buttonAccent, buttonShadow, columns, container, reverseLink, title } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import ServiceList from 'site/components/services/ServiceList';
import Swipe from 'site/components/swipe/SwipeIcon';
import { PagePublicTypeEnum, useMainPageQuery } from 'site/graphql';
import { useAuth } from '../auth/AuthProvider';
import TextBlock from '../pages/TextBlock';
import Partners from '../partners/Partners';
import MainReviews from './MainReviews';
import MainSubscription from './MainSubscription';
import VectorIcon from './vector.svg';
import MainCourses from './MainCourses';

gql`
    query MainPage($pageFilter: PageListRequest) {
        accounts {
            count
        }
        pages(filter: $pageFilter) {
            count
            result {
                id
                title
                slug
                content
                shortDescription
                type
            }
        }
    }
`;

const slugs = ['banner-button'];

const Main: FC<WithStyles<typeof styles>> = ({ classes }) => {
    const { isLoggedIn } = useAuth();
    const links = useLinks();
    const { isMobile } = useDeviceInfo();

    const { loading, data } = useMainPageQuery({
        fetchPolicy: 'network-only',
        variables: {
            pageFilter: { type: PagePublicTypeEnum.TextBlock, slugs },
        },
    });

    if (loading) {
        return <CenterLoader />;
    }

    // Разбор ответа
    const {
        accounts: { count: usersCount },
        pages: { result: pages },
    } = data;
    const bannerButton = pages.find(page => page.slug === 'banner-button');

    return (
        <>
            <div className={classes.container}>
                <div className={classes.columns}>
                    <div className={classes.columnsHead}>
                        <TextBlock slug="banner-first" />
                        <TextBlock slug="banner-second" />
                        {bannerButton && (
                            <div className={classes.bannerButtonWrapper}>
                                <TextBlock slug="banner-button" />
                            </div>
                        )}
                        <span className={classes.textCenter}>
                            Нас уже {usersCount.toLocaleString('ru')}, присоединяйся!
                        </span>
                        <div className={classes.bannerBgr}>
                            <TextBlock slug="banner-bgr" />
                        </div>
                    </div>

                    <h2 className={classes.title}>Курсы</h2>
                    <MainCourses />
                    <div className={classes.headLineWrapper}>
                        <Link to={links.courseList.url()} className={classes.subHeadLine}>
                            Все курсы
                            <div className={classes.subHeadLineVector}>
                                <VectorIcon style={{ stroke: '#1E252F' }} />
                                <VectorIcon style={{ stroke: '#9D9E9F' }} />
                            </div>
                        </Link>
                    </div>

                    {!isLoggedIn && (
                        <>
                            <h2 className={classes.title}>Актуальные отзывы</h2>
                            <Swipe />
                            <MainReviews />
                            <div className={classes.headLineWrapper}>
                                <links.otzyvy.Link to={links.otzyvy.url()} className={classes.subHeadLine}>
                                    Все отзывы
                                    <div className={classes.subHeadLineVector}>
                                        <VectorIcon style={{ stroke: '#1E252F' }} />
                                        <VectorIcon style={{ stroke: '#9D9E9F' }} />
                                    </div>
                                </links.otzyvy.Link>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {!isLoggedIn && <MainSubscription />}

            <div className={classes.container}>
                <div className={classes.columns}>
                    <h2 className={classes.title}>Сервисы</h2>
                    <Swipe />
                    <ServiceList isPlaying={!isMobile} />
                    <div className={classes.headLineWrapper}>
                        <Link to={links.servicePage.url()} className={classes.subHeadLine}>
                            Все сервисы
                            <div className={classes.subHeadLineVector}>
                                <VectorIcon style={{ stroke: '#1E252F' }} />
                                <VectorIcon style={{ stroke: '#9D9E9F' }} />
                            </div>
                        </Link>
                    </div>
                    <h2 className={classes.title}>Партнеры</h2>
                    <Swipe />
                    <Partners />
                </div>
            </div>
        </>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        container: {
            ...container(theme),
        },
        columns: {
            ...columns,
        },
        title: {
            fontSize: '2em',
            margin: '0.67em 0',
            textAlign: 'center',
        },
        bannerButtonWrapper: {
            textAlign: 'center',
            '& a': {
                marginBottom: 27,
                ...button(theme),
                ...buttonAccent(theme),
                ...buttonShadow,
                fontWeight: 700,
                fontSize: '1rem',
                padding: '0.8rem',
                height: '100%',
                color: '#FFF',
                textDecoration: 'none',
            },
        },
        bannerUsersCount: {
            fontSize: 16,
            color: '#FFF',

            [theme.breakpoints.up('sm')]: {
                color: '#999999',
            },
        },

        headLineWrapper: {
            display: 'flex',
            justifyContent: 'center',
            margin: '20px 0',

            [theme.breakpoints.up('sm')]: {
                justifyContent: 'flex-end',
            },
        },
        headLine: {
            ...title({ mode: 'bottom', size: 'h2' }),
        },
        subHeadLine: {
            ...reverseLink('#333333'),
            display: 'flex',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
                color: theme.colors.accent,
            },
        },
        subHeadLineVector: {
            marginLeft: 10,
        },
        leftHeadLine: {
            ...title({ mode: 'bottom', size: 'h2' }),
            [theme.breakpoints.up('sm')]: {
                textAlign: 'left',
                '&::after': {
                    margin: 0,
                },
            },
        },
        about: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
        },
        columnsHead: {
            position: 'relative',
            overflow: 'hidden',
            display: 'block',
            textAlign: 'center',
            paddingTop: 25,
            paddingBottom: 25,
        },
        textCenter: {
            alignSelf: 'center',
            textAlign: 'center',
        },
        bannerBgr: {
            position: 'absolute',
            zIndex: -1,
            height: '100%',
            width: '100%',
            top: '0px',

            '& div': {
                height: '100%',
                width: '100%',
            },
        },
    });

export default injectSheet(styles, 'Main')(Main);
