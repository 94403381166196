import { graphqlApiSite } from 'core/api/graphql';
import { showSnackbar } from 'core/components/snackbar';
import { button, buttonAccent, buttonShadow, columns, container } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React, { FC, useCallback, useState } from 'react';

const styles = (theme: ITheme) =>
    createStyles({
        container: {
            ...container(theme),
        },
        columns: {
            ...columns,
        },
        backgroundMail: {
            width: '100%',
            height: '100%',
            backgroundColor: '#1E252F',
            color: '#FFFFFF',

            [theme.breakpoints.down('xs')]: {
                height: '100%',
            },
        },
        containerMail: {
            ...container(theme),
            textAlign: 'center',
            padding: 30,
            color: '#FFFFFF',
        },
        title: {
            fontSize: '1.5rem',
            lineHeight: '1.75rem',
            textAlign: 'center',
            fontWeight: 'bold',
            [theme.breakpoints.down('xs')]: {
                fontSize: '1.375rem',
                lineHeight: '1.625rem',
            },
        },
        subTitle: {
            fontSize: '0.875rem',
            lineHeight: '1rem',
            textAlign: 'center',
            fontWeight: 'normal',
        },
        formMail: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
        },
        formMailInput: {
            display: 'block',
            width: '70%',
            height: 45,
            paddingLeft: 15,
            border: 'none',
            marginTop: 15,
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        formMailSubmit: {
            ...button(theme),
            ...buttonAccent(theme),
            ...buttonShadow,
            fontWeight: 700,
            display: 'block',
            width: '20%',
            minWidth: 170,
            height: 47,
            border: 'none',
            marginTop: 15,
            backgroundColor: theme.colors.accent,
            color: '#FFFFFF',
            cursor: 'pointer',

            '&:disabled': {
                backgroundColor: theme.colors.accentDark,
                cursor: 'auto',
            },
        },
    });

const MainSubscription: FC<WithStyles<typeof styles>> = ({ classes }) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const changeEmail = useCallback((event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value), []);
    const submit = useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            setLoading(true);

            const mutation = `
                mutation {
                    subscribeByEmail(email: "${email}")
                }
            `;

            graphqlApiSite<{ subscribeByEmail: boolean }>(mutation, null).then(
                () => {
                    setSuccess(true);
                    showSnackbar({ snackbarMessage: 'Ваш email успешно добавлен!' });
                    setLoading(false);
                },
                error => {
                    showSnackbar({ snackbarErrors: error });
                    setLoading(false);
                },
            );
        },
        [email, setLoading, setSuccess],
    );

    return (
        <div className={classes.backgroundMail}>
            <div className={classes.container}>
                <div className={classes.columns}>
                    <div className={classes.containerMail}>
                        <h2 className={classes.title}>Рассылка от Красного Циркуля</h2>
                        <h3 className={classes.subTitle}>
                            Подпишись на рассылку о ближайших новых курсах и сервисах на нашем сайте, а также актуальных
                            статьях о трейдинге, инвестициях и финансах
                        </h3>
                        {success ? (
                            <div>Спасибо за подписку!</div>
                        ) : (
                            <form className={classes.formMail} onSubmit={submit}>
                                <input
                                    value={email}
                                    onChange={changeEmail}
                                    placeholder="Введите свой email"
                                    className={classes.formMailInput}
                                    disabled={loading}
                                ></input>
                                <input
                                    type="submit"
                                    value="Подписаться"
                                    className={classes.formMailSubmit}
                                    disabled={loading}
                                ></input>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default injectSheet(styles)(MainSubscription);
