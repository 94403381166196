import c from 'classnames';
import CarouselNew from 'core/components/carousel-new/CarouselNew';
import FeedbackDescription from 'site/components/feedback-product/FeedbackDescription';
import FeedbackDescriptionModal from 'site/components/feedback-product/FeedbackDescriptionModal';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';
import { FeedbackToProductOrderEnum, useGetFeedbacksQuery } from 'site/graphql';
import useReviewModal from '../reviews/hooks/useReviewModal';
import ReviewProductSection from '../reviews/ReviewProductSection';
import ReviewsTable from '../reviews/ReviewsTable';

const MainReviews: React.FC<WithStyles<typeof styles>> = ({ classes }) => {
    const [isReadMoreModalOpen, selectedFeedbackToProduct, handleModalOpen, handleModalClose] = useReviewModal();
    const { data, loading } = useGetFeedbacksQuery({
        variables: {
            filter: {
                order: FeedbackToProductOrderEnum.Date,
                direction: 'desc',
                take: 3,
                skip: 0,
                onlyTop: true,
                onlyVisible: true,
            },
        },
    });

    if (loading) {
        return null;
    }

    const feedbacks = data.feedbackToProducts.result.slice(0, 3);

    return (
        <>
            <div className={c(classes.rootDesktop, classes.hideIfMobile)}>
                <ReviewsTable feedbacks={feedbacks} />
            </div>
            <div className={c(classes.rootMobile, classes.hideIfDesktop)}>
                <CarouselNew>
                    {feedbacks.map(feedback => (
                        <div className={classes.reviewMobile} key={feedback.id}>
                            <div>
                                <b>{feedback.accountName}</b>
                            </div>
                            <div className={classes.mobileDescriptionWrapper}>
                                <FeedbackDescription feedbackToProduct={feedback} onReadMore={handleModalOpen} />
                            </div>
                            <div className={classes.reviewProductSectionWrapper}>
                                <ReviewProductSection feedback={feedback} />
                            </div>
                        </div>
                    ))}
                </CarouselNew>
                <FeedbackDescriptionModal
                    feedbackToProduct={selectedFeedbackToProduct}
                    onCloseModal={handleModalClose}
                    isOpen={isReadMoreModalOpen}
                />
            </div>
        </>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        rootDesktop: {
            margin: '0 100px',
        },
        rootMobile: {},
        reviewMobile: {
            display: 'flex',
            flexDirection: 'column',
        },
        mobileDescriptionWrapper: {
            flex: 1,
        },
        reviewProductSectionWrapper: {
            paddingTop: 1 + 'rem',
        },
        hideIfMobile: {
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        hideIfDesktop: {
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
    });

export default injectSheet(styles)(MainReviews);
