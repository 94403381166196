import 'pure-react-carousel/dist/react-carousel.es.css';
import React from 'react';
import CarouselNew from '../../../core/components/carousel-new/CarouselNew';
import useServices from './hooks/useServices';
import ServiceCard from './ServiceCard';

type ServiceListProps = {
    isPlaying?: boolean;
};

const ServiceList: React.FC<ServiceListProps> = ({ isPlaying }) => {
    const cards = useServices();

    return (
        <CarouselNew isPlaying={isPlaying} naturalSlideHeight={0.95}>
            {cards.map((card, i) => {
                const CardComponent = ServiceCard;
                return <CardComponent key={i} service={card} />;
            })}
        </CarouselNew>
    );
};

export default ServiceList;
